import React from 'react'
import createSVGComponent from './createSVGComponent'

export default createSVGComponent(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9839 12.53C21.0937 10.6693 20.6231 8.82039 19.637 7.23861C18.6509 5.65682 17.1981 4.42022 15.479 3.69959C13.76 2.97895 11.8596 2.80983 10.0404 3.21558C8.22111 3.62134 6.5727 4.58194 5.3228 5.96474C4.0729 7.34754 3.28318 9.08431 3.0627 10.9352C2.84222 12.7861 3.20186 14.6598 4.09195 16.2975C4.98204 17.9352 6.35866 19.2561 8.03171 20.0779C9.70475 20.8997 11.5917 21.1817 13.4319 20.885M11.9999 7.00005V12L14.9999 15M18.9999 16L16.9999 19H20.9999L18.9999 22"
      stroke="#7A8597"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
