import * as React from 'react'
import createSVGComponent from './createSVGComponent'

export default createSVGComponent(
  <svg
    width="183"
    height="55"
    viewBox="0 0 183 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.2531 22.1515C56.2635 23.0663 55.251 24.1646 54.2529 25.412C61.7935 25.8249 66.747 29.1083 66.747 33.8456C66.747 35.0414 66.4315 36.3118 65.8091 37.6223C64.4811 40.4182 62.8748 42.9159 61.2113 44.9978C60.216 46.2452 59.2006 47.3435 58.2111 48.2611C56.0542 50.257 54.0263 51.4098 52.6123 51.4098C49.4687 51.4098 43.2761 45.7462 39.4154 37.6223C38.793 36.3118 38.4775 35.0414 38.4775 33.8456C38.4775 29.3722 42.9004 26.1949 49.7469 25.5095C50.6418 24.2019 51.6055 22.9516 52.6123 21.7988C42.0227 21.7988 34.9094 26.6393 34.9094 33.8456C34.9094 35.5748 35.3425 37.3613 36.1944 39.1536C39.8371 46.8245 46.8327 54.9771 52.6151 54.9771C55.9165 54.9771 59.6079 52.3188 62.8519 48.6138C63.8587 47.4611 64.8253 46.2108 65.7201 44.9032C66.9908 43.0478 68.1209 41.0864 69.0387 39.1565C69.8906 37.3642 70.3208 35.5777 70.3208 33.8485C70.3208 27.7548 65.2239 23.3674 57.2531 22.1515Z"
      fill="#2474A3"
    />
    <path
      d="M79.2726 31.259C75.6271 23.5882 68.6343 15.4355 62.8519 15.4355C59.5505 15.4355 55.8562 18.0938 52.6151 21.7988C51.6083 22.9516 50.6446 24.2018 49.7497 25.5095C48.4791 27.3648 47.349 29.3263 46.4312 31.259C45.5793 33.0513 45.1462 34.8378 45.1462 36.5641C45.1462 42.6549 50.2431 47.0452 58.2111 48.2611C59.2006 47.3435 60.216 46.2452 61.2112 44.9978C53.6706 44.5848 48.7143 41.3014 48.7143 36.5641C48.7143 35.3683 49.0298 34.098 49.6522 32.7875C50.9802 29.9887 52.5893 27.4939 54.25 25.412C55.2453 24.1646 56.2606 23.0663 57.2502 22.1515C59.4071 20.1556 61.435 19.0029 62.849 19.0029C65.9926 19.0029 72.1852 24.6664 76.0458 32.7875C76.6682 34.098 76.9837 35.3683 76.9837 36.5641C76.9837 41.0376 72.5609 44.212 65.7173 44.9003C64.8224 46.2079 63.8586 47.461 62.849 48.611C73.4357 48.611 80.5518 43.7704 80.5518 36.5641C80.5547 34.8378 80.1245 33.0513 79.2726 31.259Z"
      fill="#8BC33C"
    />
    <path
      d="M57.7349 39.0303C55.4059 39.0303 53.51 37.1348 53.51 34.8063C53.51 32.4778 55.4059 30.5823 57.7349 30.5823C60.064 30.5823 61.9599 32.4778 61.9599 34.8063C61.9599 37.1348 60.064 39.0303 57.7349 39.0303ZM57.7349 31.7379C56.0427 31.7379 54.6688 33.1144 54.6688 34.8034C54.6688 36.4953 56.0455 37.8689 57.7349 37.8689C59.4272 37.8689 60.8011 36.4924 60.8011 34.8034C60.8011 33.1144 59.4243 31.7379 57.7349 31.7379Z"
      fill="#444444"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84 54H88V0H84V54ZM17.5005 55C15.466 55 14.1696 54.2251 13.5364 52.6308L0 17H4.81942L17.1902 49.4079L17.2013 49.4346C17.28 49.6242 17.4088 49.9344 17.5238 50.1734C17.6283 49.9342 17.737 49.6203 17.7916 49.4628L17.8017 49.4337L30.1817 17H35L21.4679 52.6203C20.8229 54.2471 19.5631 55 17.5005 55ZM98 42.9848C98 49.8822 102.158 54 109.122 54H115V49.7204H108.522C104.697 49.7204 102.413 47.315 102.413 43.2865V21.0579H115V16.7751H102.413V7H98V42.9848ZM133.501 55C125.098 55 119 48.497 119 39.5365V17H123.261V39.1615C123.261 45.8562 127.568 50.7148 133.501 50.7148C139.434 50.7148 143.739 45.8562 143.739 39.1615V17H148V39.5365C148 48.497 141.902 55 133.501 55ZM154.074 49.7463V54H169.909C180.729 54 183 48.6154 183 44.0974C183 35.9768 175.515 34.3021 168.911 32.8255C163.414 31.5961 158.667 30.5337 158.667 26.3046C158.667 22.8365 161.418 21.257 163.973 21.257H180.329V17H163.751C158.01 17 154 20.8571 154 26.3795C154 33.807 161.059 35.4385 167.288 36.8779C173.215 38.2478 178.333 39.4307 178.333 44.5461C178.333 48.0453 175.796 49.7463 170.578 49.7463H154.074Z"
      fill="#444444"
    />
  </svg>
)
